import { RouteRecordRaw } from 'vue-router';
import { ExternalCodeType } from '~/constant/goods-enum';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';

const constant = () => import('~/views/goods/ecology-cloud/cloud-wise/constant');
const tableColumns = () => import('~/views/goods/ecology-cloud/cloud-wise/table-columns');


const cloudWisePaths = [
  { path: 'APM', name: 'apm', goodsType: ExternalCodeType.CLOUD_WISE_APM },
  { path: 'ITPM', name: 'itpm', goodsType: ExternalCodeType.CLOUD_WISE_ITPM },
  { path: 'ITSM', name: 'itsm', goodsType: ExternalCodeType.CLOUD_WISE_ITSM },
  { path: 'APMService', name: 'apm-service', goodsType: ExternalCodeType.CLOUD_WISE_APM_SERVICE },
];
// 云智慧新购路由
const cloudWisePurchaseNew: RouteRecordRaw[] = cloudWisePaths.map(item => {
  const { path, goodsType, name } = item;
  return {
    path: `${path}/Create`,
    name: `cloud-wise-${name}-purchase-new`,
    meta: purchaseMeta,
    props: { goodsType, settlement: '/CloudWise/Settlement' },
    component: () => import('~/views/goods/ecology-cloud/cloud-wise/Purchase.vue'),
  };
});

export const purchase: RouteRecordRaw[] = [
  {
    path: 'CloudWise',
    name: 'cloud-wise-purchase',
    children: [
      ...cloudWisePurchaseNew,
      // 续费
      {
        path: 'Renewal/:instanceId/:orderNum/:ptId/:productType/:externalCode/:version',
        name: 'cloud-wise-purchase-renwal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-wise/Renewal.vue'),
      },
      // 升配
      {
        path: 'Upgrade/:instanceId/:orderNum/:ptId/:productType/:externalCode/:version',
        name: 'cloud-wise-upgrade',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-wise/Upgrade.vue'),
      },
      // 结算
      createGoodsBusinessRoute<'settlement'>({
        path: 'Settlement/:orderNum',
        name: 'cloud-wise-settlement',
        meta: purchaseMeta,
        component: 'settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/CloudWise',
          orderDetailJsonConfig: { path: constant, property: 'cloduWiseITPMJsonLabel' }
        },
      })
    ]
  }
];

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'CloudWise',
  name: 'cloud-wise-order',
  meta: individualBaseMeta,
  menuProps: { name: '云智慧', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: ExternalCodeType.CLOUD_WISE_ITPM,
    columns: { path: tableColumns, property: 'cloudWiseOrderColumn' },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '云智慧' },
      ]
    },
  }
});

export const renewal = createGoodsBusinessRoute<'renewal'>({
  path: 'CloudWise',
  name: 'cloud-wise-instance',
  component: 'renewal',
  meta: individualBaseMeta,
  menuProps: {
    name: '云智慧',
    needExpend: true
  },
  props: {
    // 默认服务管理ITSM
    externalCode: ExternalCodeType.CLOUD_WISE_ITPM,
    columns: { path: tableColumns, property: 'cloudWiseITPMRenewalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '云智慧' },
      ]
    }
  }
});