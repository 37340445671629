import { AuthClass } from '~/constant/auth-enum';
import { StorageKeys } from '~/constant/storage-keys-enum';
import { customStorage } from '~/utils/storage';
import { userStore } from '.';
import { AuthenticationForm, User } from '~/models/user';
import { authStore } from './auth';
// 获取用户认证状态方法签名
type returnBusinessStatusSign = {
  // 用户认证状态值
  status: number;
  // 审核中需要的状态集合
  inReview: number[];
  // 审核通过需要的状态集合
  pass: number[];
  // 审核中的枚举值
  inReviewEnum: typeof AuthClass.NOT_CERTIFIED;
  // 审核通过的枚举值
  passEnum: typeof AuthClass.NOT_CERTIFIED;
};
/*
 *@description: 获取用户认证状态方法
 *@author: HTR
 *@date: 2023-05-24 14:48:54
 *@params1: { returnBusinessStatusSign } authSetting 用户认证状态值 
 *@return: obj.refresh 是否显示刷新按钮
 *@return: obj.form 是否可以填写表单
 *@return: obj.pass 是否可以正常通过页面
 *@return: ...obj.result 认证枚举三字段
*/
const returnBusinessStatus = (authSetting: returnBusinessStatusSign) => {
  const { inReview, status, pass, inReviewEnum, passEnum } = authSetting;
  let result = AuthClass.NOT_CERTIFIED;
  // 附加属性
  const visible = {
    // 是否显示刷新按钮
    refresh: false,
    // 是否可以填写表单
    form: true,
    // 是否可以正常通过页面
    pass: false
  };
  // 审核中
  if (inReview.includes(status)) {
    result = inReviewEnum;
    visible.refresh = true;
  }
  // 通过认证
  if (pass.includes(status)) {
    result = passEnum;
    visible.form = false;
    visible.pass = true;
  }
  return {
    ...result,
    ...visible
  };
};

// 清除用户信息
const clearUserInfo = () => {
  // 清除token
  customStorage.removeItem(StorageKeys.TOKEN);
  // 清除移动云token
  customStorage.removeItem(StorageKeys.MOBILE_TOKEN);
  // 清除router路由字段判断
  customStorage.removeItem(StorageKeys.LOCAL_USER);
  const uStore = userStore();
  const aStore = authStore();
  // 清除用户信息
  uStore.userInfo = {} as User;
  uStore.isLogin = false;
  // 清除用户认证信息
  // 认证信息
  aStore.authenticationData = {} as AuthenticationForm;
  // 平台认证状态
  aStore.sysUserStatus = 0;
  // 移动云认证状态
  aStore.mobileUserStatus = 0;
};

export {
  returnBusinessStatus,
  clearUserInfo
};