import { ElMessage } from 'element-plus';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { EssentialRoutes } from '~/router/essential-router';
import { GoodsRoutes } from '~/router/goods-routes/index';
import { IndividualRouter, IndividualOtherRouter } from '~/router/individual-router';
import { loginBlackListRoute } from '~/constant/auth-blacklist';
import { customStorage } from '~/utils/storage';
import { StorageKeys } from '~/constant/storage-keys-enum';
import { userStore } from '~/stores/user';
import { reviewRoutesRepeat } from '~/utils/route-generate';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'layout-page',
    redirect: '/Home',
    component: () => import('~/views/layout/LayoutPage.vue'),
    children: [
      ...EssentialRoutes,
      ...GoodsRoutes,
      ...IndividualRouter,
      ...IndividualOtherRouter
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: () => import('~/views/error/NotFound.vue'),
    meta: { title: '404' },
  },
];

// 是否有重复路由名称
const envModel = import.meta.env.VITE_APP_MODE;
if (envModel === 'dev') {
  reviewRoutesRepeat(routes);
}

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  /* 获取用户信息 */
  const isLogin = customStorage.getItem(StorageKeys.TOKEN);
  window.scrollTo(0, 0);
  /* 判断是否登录 */
  if (to.meta.requireAuth && !isLogin) {
    ElMessage.warning('请先进行登录');
    userStore().isLogin = false;
    const path = loginBlackListRoute.includes(to.fullPath) ? undefined : window.btoa(to.fullPath);
    next({
      path: '/Login',
      query: { p: path }
    });
    return;
  }
  /* 已登录不跳转到登录注册和忘记密码 */
  if (loginBlackListRoute.includes(to.path) && isLogin) {
    next('/Home');
    return;
  }
  next();
});
export default router;
