/**
 *@description: 用于产品路由插入生成
 *@author: HTR
 *@date: 2023-07-10 10:36:55
*/
import { defineComponent } from 'vue';
import { RouteComponent, RouteRecordRaw } from 'vue-router';
import { OrderDetailResType, OrderListRecordsItemType } from '~/models/response-type';
import { LayzImportProps } from '~/models/utils-types';
import { individualBaseMeta as meta } from '~/router/router-meta';
import { FilterTableProps } from '~/views/goods/components/filter-table/type';
import { BreadcrumbNavPropsType } from '~/views/individual/components/BreadcrumbNav.types';

// 产品的路由类型
type ProductRouteType = 'purchase' | 'order' | 'renewal' | 'consolePage';
// 产品导出路由类型
type GoodsRouteExports = {
  [x in ProductRouteType]?: RouteRecordRaw | RouteRecordRaw[]
};
// 包含多个产品类别路由
type MultipleGoods = {
  // 产品类别名称
  menuProps?: RouteRecordRaw['menuProps'];
  // 产品类别路由
  route?: {
    [x in Exclude<ProductRouteType, 'purchase' | 'consolePage'>]: RouteRecordRaw;
  } & {
    purchase: RouteRecordRaw[],
    consolePage: RouteRecordRaw[],
  }
};
// 路由导出对象处理
type routeHandlerSign = (
  handlerObj: {
    routeMap: Map<string, MultipleGoods>;
    exportRoutes: ExportRoutes;
    key: string;
  }
) => MultipleGoods | undefined;

// 调用glob导出对象类型
type ExportRoutes = Record<string, GoodsRouteExports>;

// 生成路由组件
const createMultipRouterView = (componentName?: string) => {
  return () => Promise.resolve(
    defineComponent({
      name: componentName,
      setup: () => () => <router-view />
    })
  );
};

/**
 *@description: 多个产品的类别生成路由
 *@author: HTR
 *@date: 2023-07-10 15:06:16
 *@param: suffix: ProductRouteType 路由类别
 *@param: routeName: string 路由名称
 *@param: menuProps: RouteRecordRaw['menuProps'] 菜单配置
 *@return: RouteRecordRaw 路由对象
*/
const createMultipRouteRaw = (suffix: ProductRouteType, routeName: string, menuProps?: RouteRecordRaw['menuProps']) => {
  // 路由路径转换
  const path = routeName.replace(new RegExp(/(-?)\b\w+\b/g), word => {
    const index = word.includes('-') ? 1 : 0;
    return word[index].toUpperCase() + word.slice(index + 1);
  });
  // 路由名称以及组件名称
  const name = `${routeName}-${suffix}`;
  // 路由组件
  const component = createMultipRouterView();
  return { path, name, meta, menuProps, component, children: [] };
};

/**
 *@description: 路由转化
 *@author: HTR
 *@date: 2023-07-10 15:05:07
 *@param: routes: RouteRecordRaw | RouteRecordRaw[] 路由/路由集合
 *@return: RouteRecordRaw[] 路由集合
*/
const routeArray = (routes?: RouteRecordRaw | RouteRecordRaw[]) => {
  if (routes) {
    return Array.isArray(routes) ? routes : [routes];
  }
  return [];
};

/**
 *@description: 导出的路由对象处理
 *@author: HTR
 *@date: 2023-07-10 15:12:43
 *@param: { 
    folderPaths:  Record<string, number> 文件夹路径对象,
    routeMap: Map<string, MultipleGoods> 多路由文件map,
    exportRoutes: ExportRoutes 读取文件导出对象,
    key: string 当前读取的目录
  }
 *@return: 当前读取的路径是否为多文件对象
*/
const routeHandler: routeHandlerSign = ({ routeMap, exportRoutes, key }) => {
  // 获取文件夹路径
  const path = key.substring(0, key.lastIndexOf('/'));
  // 获取文件夹名称/map集合key值
  const mapKey = path.substring(path.lastIndexOf('/') + 1);
  // 从map里面拿出对应对象进行配置
  const multipleRoute = routeMap.get(mapKey);
  // 从文件夹对象中寻找是否有该路径并且该路径下的文件大于1
  if (multipleRoute) {
    // 如若没有route对象，进行初始化
    if (!multipleRoute.route) {
      const { menuProps } = multipleRoute;
      multipleRoute.route = {
        order: createMultipRouteRaw('order', mapKey, menuProps),
        renewal: createMultipRouteRaw('renewal', mapKey, menuProps),
        consolePage: [],
        purchase: []
      };
    }
    // 提取导出的各类
    const {
      order: orderChildren,
      renewal: renewalChildren,
      consolePage: consoleChildren,
      purchase: purchaseChildren
    } = exportRoutes[key];
    // 添加到chidren集合中
    const { order, renewal, consolePage, purchase } = multipleRoute.route;
    order.children?.push(...routeArray(orderChildren));
    renewal.children?.push(...routeArray(renewalChildren));
    consolePage.push(...routeArray(consoleChildren));
    purchase.push(...routeArray(purchaseChildren));
    return multipleRoute;
  }
};

// 生成产品业务路由
// 业务类型
type BusinessRouteTypePrimary = 'order' | 'old_settlement' | 'renewal' | 'settlement';
// 表格路由
type BusinessTableRoute = {
  // filter-table配置
  filterTable?: Omit<FilterTableProps, 'columns' | 'fetch'>,
  // 导航值
  navigator: BreadcrumbNavPropsType
  // 查看详情JSONLabel配置
  jsonLabel?: LayzImportProps
  // 列表调用接口
  recordsApi?: LayzImportProps
  // 列配置
  columns?: LayzImportProps,
  // 业务类型
  type?: BusinessRouteTypePrimary,
  // 产品编码
  externalCode?: string,
  // 来源
  source?: 1 | 2
}
// 结算路由参数
type BusinessSettlementProps = {
  // 订单详情字段配置
  orderDetailJsonConfig: LayzImportProps;
  // 订单路由
  orderRoute: string | ((orderInfo?: OrderDetailResType) => string);
  // orderInfo中的row
  customDetailKeys?: (keyof (OrderDetailResType & { orderNum: string }))[];
  // 是否需要交付
  isNeedDeliver?: boolean
}
type PayRouteRowType = OrderListRecordsItemType & {
  externalCode: string,
  orderItem: string
}
// 每个不同的业务路由参数类型
type BusinessRouteTypes = {
  // 订单类型
  order: BusinessTableRoute & {
    // 是否校验订单
    verifyOrder?: boolean,
    // 支付路由
    paymentRoute?: (row: PayRouteRowType) => Promise<string> | string;
  },
  // 续费路由
  renewal: BusinessTableRoute,
  // 老产品类型
  old_settlement: {
    // 产品header
    header?: LayzImportProps;
  } & BusinessSettlementProps;
  // 新产品类型
  settlement: BusinessSettlementProps;
}

type GetBusinessRouteType<T> = T extends BusinessRouteTypePrimary ? BusinessRouteTypes[T] : never;

// 公用组件懒加载类型
type LazyComponent = Promise<{ default: RouteComponent }>;

// 每个不同业务的公用组件
const publishBusinessComponents: Record<BusinessRouteTypePrimary, LazyComponent> = {
  // 订单
  order: import('~/views/goods/components/ecology-cloud/GoodsTable.vue'),
  // 续费
  renewal: import('~/views/goods/components/ecology-cloud/GoodsTable.vue'),
  // 老产品结算
  old_settlement: import('~/views/goods/components/ecology-cloud/order-peyment/old-payment/NoCouponSettlement.vue'),
  // 新产品结算
  settlement: import('~/views/goods/components/ecology-cloud/order-peyment/new-payment/OrderSettlement.vue')
};

// 组件生成函数
type RouteRecordType<T> = {
  props: GetBusinessRouteType<T>,
  component: T,
} & Omit<RouteRecordRaw, 'props' | 'component'>;
function createGoodsBusinessRoute<T extends BusinessRouteTypePrimary> (routeRecord: RouteRecordType<T>) {
  const { component: type, name, props } = routeRecord;
  const businessComponent = publishBusinessComponents[type];
  const component = () => businessComponent.then(res => ({ ...res['default'], name }));
  return { ...routeRecord, props: { ...props, type }, component } as RouteRecordRaw;
}

// 判断路由是否有重复name
// 获取所有路由，是否有重复路由名称
const reviewRoutesRepeat = (routes: RouteRecordRaw[]) => {
  const routeName: Record<string, number> = {};
  const getAllRoute = (routeRows: readonly RouteRecordRaw[]) => {
    if (routeRows.length) {
      for (let i = 0; i < routeRows.length; i++) {
        const { name, children } = routeRows[i];
        if (name) {
          if (!routeName[name.toString()]) {
            routeName[name.toString()] = 1;
          } else {
            const errorDiv = document.createElement('div');
            errorDiv.classList.add('c-red');
            errorDiv.style.position = 'fixed';
            errorDiv.style.top = '0';
            errorDiv.style.left = '0';
            errorDiv.style.width = '100%';
            errorDiv.style.height = '100%';
            errorDiv.style.background = 'rgba(0, 0, 0, 0.5)';
            errorDiv.style.zIndex = '9999';
            errorDiv.style.color = 'red';
            errorDiv.style.fontSize = '20px';
            errorDiv.style.textAlign = 'center';
            errorDiv.style.lineHeight = '100vh';
            errorDiv.innerText = `路由name出现重复:${name.toString()}`;
            document.body.appendChild(errorDiv);
            throw new Error(`路由name出现重复:${name.toString()}`);
          }
        }
        if (children) {
          getAllRoute(children);
        }
      }
    }
  };
  getAllRoute(routes);
};
export {
  GoodsRouteExports,
  MultipleGoods,
  ProductRouteType,
  BusinessRouteTypes,
  BusinessRouteTypePrimary,
  BusinessSettlementProps,
  createMultipRouterView,
  createGoodsBusinessRoute,
  routeHandler,
  reviewRoutesRepeat
};