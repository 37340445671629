// 可复用路由

import { GoodsFirmRouteMap } from '~/router/goods-routes';

// 百度
const baiduBlackList = [
  // 百度云产品拦截
  '/BaiduPurchase',
  // 百度云支付
  '/BaiduSettlement',
  // 百度控制台
  '/Console/BaiduCloud',
  // 百度云域名控制台
  '/Console/BaiduDomain',
];
// 阿里
const aliBlackList = [
  // 阿里云产品拦截
  '/AliPurchase',
  // 阿里云控制台
  '/Console/AliCloud',
];

// 移动云
const mobileCloudRoute = GoodsFirmRouteMap.get('mobile-cloud');
const mobileBlackList = [...mobileCloudRoute?.purchase || [], ...mobileCloudRoute?.consolePage || []];

/**
 *@description: 以下集合作为判断使用
 *企业基本信息认证黑名单路由: 基本信息认证没通过，就不能访问的路由
 *框架协议认证黑名单路由: 就算基本信息通过，但是框架协议没有通过就不能访问的路由
 *产品认证黑名单路由: 基本信息认证没通过，框架协议通过，但是有产品指定的认证没有通过就不能访问的路由
*/
// 企业基本信息认证黑名单路由
const baseInfoBlacklistRoute: string[] = [...GoodsFirmRouteMap].reduce((p, n) => {
  const { purchase, consolePage } = n[1];
  return [...p, ...purchase, ...consolePage];
}, new Array<string>());

// 框架协议认证黑名单路由
const empowerBlacklistRoute = [
  // '/Individual/Invoice/Index',
  '/Individual/User/Cash',
  ...baiduBlackList,
  ...aliBlackList,
];

// 产品认证黑名单路由
const productBlackListRoute = [
  '/Console/LandaryAuthPage',
  // 生态云 - 蓝凌业务经营云
  '/Console/Landary',
  // 生态云 - e签宝
  '/ESign',
  ...baiduBlackList,
  ...aliBlackList,
  ...mobileBlackList
];

// 登录后不能进行跳转的页面
const loginBlackListRoute = [
  '/Login',
  '/Register',
  '/Forget'
];

export {
  baseInfoBlacklistRoute,
  empowerBlacklistRoute,
  loginBlackListRoute,
  productBlackListRoute
};