import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta, consoleBaseMeta } from '~/router/router-meta';
import { createMultipRouterView } from '~/utils/route-generate';


// 移动订单路由
export const order: RouteRecordRaw[] = [
  // 列表
  {
    path: 'MobileCloud',
    name: 'mobile-cloud-order-view',
    component: createMultipRouterView(),
    redirect: '/Individual/Order/MobileCloud/List',
    meta: individualBaseMeta,
    menuProps: {
      name: '移动云',
      show: true,
    },
    children: [
      // 列表
      {
        path: 'List',
        name: 'mobile-cloud-order-list',
        component: () => import('~/views/goods/mobile-cloud/order/List.vue'),
        meta: individualBaseMeta
      },
      // 详情
      {
        path: 'Detail/:orderNum',
        name: 'mobile-cloud-detail',
        component: () => import('~/views/goods/mobile-cloud/order/Detail.vue'),
        meta: individualBaseMeta
      }
    ]
  },
];

// 移动云结算
export const purchase: RouteRecordRaw[] = [
  {
    path: '/MobileCloud/Settelement/:orderNum',
    name: 'mobile-cloud-settelement',
    meta: purchaseMeta,
    component: () => import('~/views/goods/mobile-cloud/order/MobileOrder.vue'),
  },
  // 退改续跳转中间页
  {
    path: '/mobile/rcc',
    name: 'mobile-cloud-rcc',
    component: () => import('~/views/goods/mobile-cloud/rcc/MobileRcc.vue'),
  },
  // 续费
  {
    path: '/MobileCloud/Renew',
    name: 'mobile-cloud-purchase-renwal',
    component: () => import('~/views/goods/mobile-cloud/rcc/renewal/index.vue'),
    meta: purchaseMeta,
  }
];

export const consolePage: RouteRecordRaw = {
  path: 'MobileCloud',
  name: 'mobile-console',
  component: () => import('~/views/goods/mobile-cloud/console/MobileConsole.vue'),
  meta: consoleBaseMeta
};