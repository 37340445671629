import { RouteRecordRaw } from 'vue-router';
import { consoleBaseMeta, individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createMultipRouterView } from '~/utils/route-generate';

const baiduOrderMeta = { ...purchaseMeta, notSticky: true };

const intorduceMeta = { headerOpacity: false, requireAuth: false };


export const purchase: RouteRecordRaw[] = [
  // 百度云购买页面
  {
    path: '/BaiduPurchase',
    name: 'bai-du-purchase',
    component: () => import('~/views/goods/bai-du-cloud/base-cloud/Purchase.vue'),
    meta: baiduOrderMeta
  },
  // 百度云订单结算页面
  {
    path: '/BaiduSettlement/:orderIds',
    name: 'bai-du-settlement',
    component: () => import('~/views/goods/bai-du-cloud/base-cloud/Settlement.vue'),
    meta: baiduOrderMeta
  },
  // 域名
  {
    path: '/BaiduDomain',
    name: 'bai-du-domain',
    meta: intorduceMeta,
    component: createMultipRouterView(),
    redirect: '/BaiduDomain/Introduce',
    children: [
      // 域名介绍
      {
        path: 'Introduce',
        name: 'bai-du-domian-introduce',
        component: () => import('~/views/goods/bai-du-cloud/domain/Introduce.vue'),
        meta: intorduceMeta
      },
      // 价格总览
      {
        path: 'Price',
        name: 'bai-du-domian-price',
        component: () => import('~/views/goods/bai-du-cloud/domain/Price.vue'),
        meta: { ...intorduceMeta, notSticky: true }
      },
      // 域名查询
      {
        path: 'Query',
        name: 'bai-du-domian-query',
        component: () => import('~/views/goods/bai-du-cloud/domain/Query.vue'),
        meta: intorduceMeta
      },
    ]
  }
];
const menuProps = {
  name: '百度云',
  show: false,
};

// 百度续费路由
export const renewal: RouteRecordRaw = {
  path: 'BaiduCloud',
  name: 'bai-du-renewal',
  component: () => import('~/views/goods/bai-du-cloud/base-cloud/Renewal.vue'),
  meta: individualBaseMeta,
  menuProps
};
// 百度订单路由
export const order: RouteRecordRaw[] = [
  // 列表
  {
    path: 'BaiduCloud',
    name: 'bai-du-order-view',
    component: createMultipRouterView(),
    redirect: '/Individual/Order/BaiduCloud/List',
    meta: individualBaseMeta,
    menuProps,
    children: [
      // 列表
      {
        path: 'List',
        name: 'bai-du-order-list',
        component: () => import('~/views/goods/bai-du-cloud/base-cloud/OrderList.vue'),
        meta: individualBaseMeta
      },
      // 详情
      {
        path: 'Detail/:orderId',
        name: 'bai-du-order-detail',
        component: () => import('~/views/goods/bai-du-cloud/base-cloud/OrderDetail.vue'),
        meta: individualBaseMeta
      }
    ]
  },
  
];

// 百度控制台
export const consolePage: RouteRecordRaw[] = [
  {
    path: 'BaiduCloud',
    name: 'bai-du-console',
    component: () => import('~/views/goods/bai-du-cloud/base-cloud/Console.vue'),
    meta: consoleBaseMeta
  },
  {
    path: 'BaiduDomain',
    name: 'bai-du-domain-console',
    component: () => import('~/views/goods/bai-du-cloud/domain/Console.vue'),
    meta: consoleBaseMeta
  }
];