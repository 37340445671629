import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';

// 思通NLP产品
export const purchase: RouteRecordRaw[] = [
  {
    path: 'StoneNLP',
    name: 'stone-nlp-purchase',
    component: () => import('~/views/goods/ecology-cloud/stone-nlp/Purchase.vue'),
    meta: purchaseMeta
  },
  {
    path: 'StoneNLPService',
    name: 'stone-nlp-service',
    component: () => import('~/views/goods/ecology-cloud/stone-nlp/Console.vue'),
    meta: purchaseMeta,
  }
];
export const order: RouteRecordRaw[] = [
  {
    path: 'StoneNLP',
    name: 'stone-nlp-order',
    component: () => import('~/views/goods/ecology-cloud/stone-nlp/OrderList.vue'),
    meta: individualBaseMeta,
    menuProps: {
      name: 'NLP自然语言处理引擎',
      show: true
    }
  }
];
