import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileBsbak',
  name: 'mobile-bsbak',
  redirect: '/MobileBsbak/Purchase',
  children: [
    // 开通
    {
      path: 'Purchase',
      name: 'bsbak-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/bsbak/new/index.vue'),
      meta: purchaseMeta,
    },
    // 资源包
    {
      path: 'Resource',
      name: 'bsbak-purchase-resource',
      component: () => import('~/views/goods/mobile-cloud/purchase/bsbak/resource/index.vue'),
      meta: purchaseMeta,
    }
  ]
};
