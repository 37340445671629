import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileEbs',
  name: 'mobile-ebs',
  redirect: '/MobileEbs/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'ebs-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/ebs/new/index.vue'),
      meta: purchaseMeta,
    },
    // 扩容
    {
      path: 'Change',
      name: 'ebs-purchase-change',
      component: () => import('~/views/goods/mobile-cloud/purchase/ebs/change/index.vue'),
      meta: purchaseMeta,
    }
  ]
};
