import qs from 'qs';
import { APIRequest } from '~/api/config';
import { NavType } from '~/models/nav-types';
import {
  AreaTree,
  BannerExpend,
  BannerRecord,
  DomianHostRecoerd,
  EAdvantageRecord,
  HomeConfigRecord,
  HomeSolveRecord,
  MobileAreaTree,
  SolutionBannerInfo,
  SolutionByCateForm,
  SolutionClassiflyTree,
  SolutionDetailForm,
  UploadFileRecord
} from '~/models/essential/home-config';
import { ActivityModel } from '~/models/essential/activity';
import { SolutionParams, SolutionRecords } from '~/models/essential/solution-config';
import { ExportParams } from '~/models/base-types';
import { CompanyResultType } from '~/models/essential/company-introduction-config';
import { Dictionary, MobileCloudDictionary } from '~/models/response-type';
import axios, { AxiosProgressEvent } from 'axios';

const API_GLOBAL = {
  // 获取网站基础配置
  getSiteConfig (deptId: string) {
    return APIRequest.get<HomeConfigRecord>({
      url: '/hoc/hochomebaseinfo/getHomeBaseInfoByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 获取banner
  getHomeBanner (deptId: string) {
    return APIRequest.get<BannerRecord[]>({
      url: '/hoc/hochomecarousel/getHocHomeCarouselByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 获取banner下方活动
  getHomeBannerActivity (tenantId: string) {
    return APIRequest.get<BannerExpend>({
      url: 'blade-vstec/exrotationexpand/page',
      params: { page: 1, size: 7, tenantId },
      isNeedLogin: false
    });
  },
  // 获取首页热门解决方案
  getHomeSolve (deptId: string) {
    return APIRequest.get<HomeSolveRecord[]>({
      url: 'hoc/hocsolveprogramme/getHocSolveProgramme',
      params: { deptId },
      isNeedLogin: false,
      canBeCancel: false,
    });
  },
  // 获取解决方案banner配置信息
  fetchSolutionBannerInfo (deptId: string) {
    return APIRequest.get<SolutionBannerInfo>({
      url: 'hoc/hocsolveprogrammebanner/getHocSolveProgrammeBannerByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 获取解决方案分类
  getSolutionClassify (deptId: string) {
    return APIRequest.get<SolutionClassiflyTree[]>({
      url: 'hoc/hocsolveprogrammetype/getHocSolveProgrammeTypeTreeByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 获取解决分类下的解决方案
  getSolutionsByCate (params: SolutionByCateForm) {
    return APIRequest.get<HomeSolveRecord[]>({
      url: 'hoc/hocsolveprogramme/getHocSolveProgrammeByTypeId',
      params,
      isNeedLogin: false
    });
  },
  // 企业优势
  getHomeAdvantage (deptId: string) {
    return APIRequest.get<EAdvantageRecord[]>({
      url: 'hoc/hochomeproductadvantage/getHocHomeProductAdvantageByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 获取解决方案列表
  getSolutionMap (params: SolutionParams) {
    return Promise.resolve<SolutionRecords>({
      records: [],
      total: 0,
      params
    } as never as SolutionRecords);
  },
  // 根据id获取解决方案详情
  getSolutionInfo (params: SolutionDetailForm) {
    return APIRequest.get<HomeSolveRecord>({
      url: 'hoc/hocsolveprogramme/getHocSolveProgrammeDetailById',
      params,
      isNeedLogin: false
    });
  },
  // 导出excel数据
  exportExcel <T = any>(params: ExportParams<T>) {
    return APIRequest.get<BlobPart>({
      url: `blade-vstec/dtorder/exportExcelData?${qs.stringify(params)}`,
      responseType: 'blob'
    });
  },
  // 根据域名获取对应的租户id
  getTenantPrimary () {
    // 本地启动时 使用配置的开发域名 否则使用当前浏览器域名
    const { VITE_ONLINE, VITE_DOMAIN } = import.meta.env;
    const { protocol, hostname } = location;
    const domain = `${protocol}//${hostname}`;
    const address = VITE_ONLINE ? domain : VITE_DOMAIN;
    return APIRequest.get<DomianHostRecoerd>({
      url: 'admin/dept/getByAddress',
      params: { address },
      isNeedLogin: false
    });
  },
  // 网站上传文件
  globalUpload (params: FormData, uploadProgress?: (event: AxiosProgressEvent) => void) {
    return APIRequest.post<UploadFileRecord>({
      url: 'oss/sysoss/fileUpload',
      data: params,
      retry: 0,
      timeout: 1000 * 60 * 5,
      onUploadProgress: uploadProgress,
    });
  },
  // 获取公司介绍信息
  getCompanyIntroductionMap (deptId: string) {
    return APIRequest.get<CompanyResultType>({
      url: 'hoc/hochomecompanyintroduction/getHocHomeCompanyIntroductionByDeptId',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 业务字典
  getDictionary (code: string) {
    return APIRequest.get<Dictionary[]>({
      url: `admin/dict/type/${code}`,
      isNeedLogin: false
    });
  },
  // 移动云 业务字典
  getMobileCloudDictionary (code: string) {
    return APIRequest.get<MobileCloudDictionary[]>({
      url: `admin/dict/type/${code}`,
      isNeedLogin: false
    });
  },
  
  // 获取当前代理商名字
  getMyTenantName (tenantId: string) {
    return APIRequest.get<string>({
      url: 'blade-user/tenant/getTenantNameByTenantId',
      params: { tenantId },
      isNeedLogin: false
    });
  },
  // 获取活动列表
  getActivityList (tenantId: string) {
    tenantId;
    return Promise.resolve<ActivityModel[]>([]);
  },
  // 领取活动优惠券
  receiveActivityCoupon (id: string) {
    return APIRequest.post<boolean>({
      url: '/blade-vstec/acactivitycenterconfig/receiveCoupon',
      params: { id }
    });
  },
  // 上传oss
  uploadOss (data: any) {
    return APIRequest.post<{ filed: string, url: string }>({
      url: '/oss/sysoss/webConfigUpload',
      data,
    });
  },
  // 获取ossJSON
  getOssJson <T>(url: string) {
    return axios.get<T>(decodeURIComponent(url));
  },
  // 获取省市区数据-older
  getAreaTreeData () {
    return APIRequest.get<AreaTree[]>({
      url: 'admin/sysadmarea/treeList',
      isNeedLogin: false
    });
  },
  // 获取移动云省市区数据
  getMobileCloudAreaTreeData () {
    return APIRequest.get<MobileAreaTree[]>({
      url: 'admin/sysmobileregion/treeList',
      isNeedLogin: false
    });
  },
  // 获取导航栏
  getNavList (deptId: string) {
    return APIRequest.get<NavType[]>({
      url: 'pms/pmsdeptproduct/getNavList',
      isNeedLogin: false,
      params: { deptId }
    });
  },
  // 获取当前代理商分配产品及导航id
  getAllocateProductsByDeptId (deptId: string) {
    return APIRequest.get<string>({
      url: 'pms/pmsdeptproduct/getPtIdsByDeptId',
      isNeedLogin: false,
      params: { deptId }
    });
  },
  // 通过deptId获取当前代理商绑定的域名
  getDomainByDeptId (deptId: string) {
    return APIRequest.get<string>({
      url: 'admin/sysdeptinner/getAddressByDeptId',
      isNeedLogin: false,
      params: { deptId }
    });
  },
};

export default API_GLOBAL;
