import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute, createMultipRouterView } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';
import { getQueryLink } from '~/utils/util';


// 订单、续费路由文件配置
const tableConfig = () => import('~/views/goods/ecology-cloud/cloud-dream/table.config');
// 常量文件配置
const constant = () => import('~/views/goods/ecology-cloud/cloud-dream/constant');

export const purchase: RouteRecordRaw[] = [
  {
    path: 'CloudDream',
    name: 'cloud-dream',
    children: [
    
      /* 新购 */
      {
        path: 'Purchase',
        name: 'speeded-website-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-dream/Purchase.vue'),
      },
      /* 续费 */
      {
        path: 'Renwal',
        name: 'cloud-dream-purchase-renwal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-dream/Renew.vue'),
      }]
  }
];


export const order: RouteRecordRaw = {
  path: 'CloudDream',
  name: 'cloud-dream-order',
  component: createMultipRouterView('cloud-dream-order'),
  redirect: '/Individual/Order/EcologyCloud/CloudDream/cloudDreamWebsite',
  meta: individualBaseMeta,
  menuProps: {
    name: '云梦建站',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'order'>({
      path: 'cloudDreamWebsite',
      name: 'cloud-dream-website-order',
      meta: individualBaseMeta,
      menuProps: { name: '云·速成美站', show: true },
      component: 'order',
      props: {
        source: 2,
        externalCode: ExternalCodeType.CLOUD_DREAM_WEBSITE,
        jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
        paymentRoute: row => {
          const { ptId, orderNum } = row;
          const path = '/CloudDream/Purchase';
          const query = { id: ptId, orderNum };
          const link = getQueryLink({ path, query });
          return link;
        },
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '生态云' },
            { text: '云梦建站' },
            { text: '云·速成美站' }
          ]
        },
      }
    }),
    createGoodsBusinessRoute<'order'>({
      path: 'cloudDreamWebdesign',
      name: 'cloud-dream-webdesign-order',
      meta: individualBaseMeta,
      menuProps: { name: '云·企业官网', show: true },
      component: 'order',
      props: {
        source: 2,
        externalCode: ExternalCodeType.CLOUD_DREAM_WEBDESIGN,
        jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
        paymentRoute: row => {
          const { ptId, orderNum } = row;
          const path = '/CloudDream/Purchase';
          const query = { id: ptId, orderNum };
          const link = getQueryLink({ path, query });
          return link;
        },
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '生态云' },
            { text: '云梦建站' },
            { text: '云·企业官网' }
          ]
        },
      }
    }),
  ]
};

export const renewal = {
  path: 'CloudDream',
  name: 'cloud-dream-instance',
  component: createMultipRouterView('cloud-dream-renewal'),
  redirect: '/Individual/Renwal/EcologyCloud/CloudDream/SpeededWebsite',
  meta: individualBaseMeta,
  menuProps: {
    name: '云梦建站',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'renewal'>({
      path: 'CloudDreamWebsite',
      name: 'cloud-dream-website-renewal',
      meta: individualBaseMeta,
      menuProps: { name: '云·速成美站', show: true },
      component: 'renewal',
      props: {
        externalCode: ExternalCodeType.CLOUD_DREAM_WEBSITE,
        columns: { path: tableConfig, property: 'renewalColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: '生态云' },
            { text: '云梦建站' },
            { text: '云·速成美站' }
          ]
        }
      }
    }),
    createGoodsBusinessRoute<'renewal'>({
      path: 'CloudDreamWebdesign',
      name: 'cloud-dream-webdesign-renewal',
      meta: individualBaseMeta,
      menuProps: { name: '云·企业官网', show: true },
      component: 'renewal',
      props: {
        externalCode: ExternalCodeType.CLOUD_DREAM_WEBDESIGN,
        columns: { path: tableConfig, property: 'renewalColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: '生态云' },
            { text: '云梦建站' },
            { text: '云·企业官网' }
          ]
        }
      }
    }),
  ]
};
