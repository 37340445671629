import { ElMessage } from 'element-plus';
import { localUserToken, User } from '~/models/user';
import { defineStore } from 'pinia';
import { customStorage } from '~/utils/storage';
import API_USER from '~/api/user-enter';
import router from '~/router';
import { StorageKeys } from '~/constant/storage-keys-enum';
import { UserLoginStatus } from '~/constant/auth-enum';
import { ref, computed } from 'vue';
import { clearUserInfo } from './user-store-util';
import { API_MOBILE_INFO } from '~/api/product/mobile-cloud/info';

// token时长，后期放公共环境中
const TOKEN_DURATION = 1000 * 60 * 60 * 24 - 10000;

export const userStore = defineStore('user', () => {
  // state
  // 用户信息
  const userInfo = ref<User>({} as User);
  // 用户余额
  const userBalance = ref<string>('0');
  // action
  /*
    *@description: 登录
    *@author: HTR
    *@date: 2023-03-22 14:19:46
    *@params: { string } username 登录名
    *@params: { string } password 密码
    *@return: { number } 结果返回值
   */
  const login = async (name: string, password: string, verification?: string) => {
    // 调用接口
    const res = await API_USER.LoginUser({
      username: name,
      password,
      grant_type: 'password',
      scope: 'server',
      verification
    }).catch(error => {
      return error?.response?.data || { code: UserLoginStatus.MSG_FAIL, msg: '登录错误，请稍后再试' };
    });
    if (res.username) {
      // 存储token
      const { access_token: accessToken, tenant_name: tenantName } = res;
      customStorage.setItem<localUserToken>({
        key: StorageKeys.TOKEN,
        value: {
          access_token: accessToken,
          tenant_name: tenantName
        },
        timer: TOKEN_DURATION
      });
    }
    // 获取code结果
    const code = res?.username ? UserLoginStatus.LOGIN_SUCCESS : +res?.code ?? UserLoginStatus.ACCOUNT_ERROR;
    // 错误提示
    if (code !== UserLoginStatus.LOGIN_SUCCESS && code !== UserLoginStatus.NEED_MSG) {
      ElMessage.warning(res?.msg || res.error_description);
    }
    return code;
  };

  /*
   *@description: 获取用户信息
   *@author: HTR
   *@date: 2023-03-22 14:35:19
  */
  const getUserInfo = async () => {
    // 调取获取用户信息接口
    const result = await API_USER.getUserInfo().catch(() => clearUserInfo());
    if (result) {
      // after to do 用户缺少的字段以后要加上
      userInfo.value = result.sysUser;
      isLogin.value = true;
    }
  };
  /**
   *@description: 获取用户移动云token
   *@author: HTR
   *@date: 2024-04-26 16:16:36
   *@return: { string } token
  */
  const getUserMobileCloudToken = async () => {
    const token = customStorage.getItem<string>(StorageKeys.MOBILE_TOKEN);
    if (!token) {
      const res = await API_MOBILE_INFO.getMobileToken();
      if (res) {
        customStorage.setItem<string>({
          value: res.access_token,
          key: StorageKeys.MOBILE_TOKEN,
          // 移动云控制台时长为30分钟，这里设置20分钟
          timer: 1000 * 60 * 20
        });
      }
      return res.access_token;
    }
    return token.value;
  };

  /*
   *@description: 注销用户
   *@author: HTR
   *@date: 2023-03-22 15:55:33
   *@params1: { type } paramsName 
   *@return: { type } returnName
  */
  const cancellation = async (haveTurn = false) => {
    // 是否是含有token的注销
    if (!haveTurn) {
      await API_USER.cancellationUser();
    }
    // 清除用户信息
    clearUserInfo();
    // 注销后的跳转链接
    const path = haveTurn ? '/Login' : '/';
    await router.replace(path);
  };

  /*
   *@description: 获取用户余额
   *@author: HTR
   *@date: 2023-03-22 16:03:34
  */
  const getUserBalance = async () => {
    userBalance.value = await API_USER.getUserBalance() ?? 0;
  };

  // getter
  // 是否登录
  const isLogin = ref(Boolean(customStorage.getItem(StorageKeys.TOKEN)));

  // 当前登录状态userInfo 是否为加载完成的状态
  const userDone = computed<boolean>(() => Boolean(userInfo.value.userId));
  return {
    userInfo,
    userBalance,
    userDone,
    isLogin,
    login,
    getUserInfo,
    getUserBalance,
    cancellation,
    getUserMobileCloudToken
  };
});