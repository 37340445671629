import { JsonBig } from '~/utils/util';

export type StorageParams<T> = {
	key: string,
	value: T,
	timer?: number,
	startDate?: number
};

export const customStorage = {
  setItem<T> (params: StorageParams<T>): void {
    if (!params.timer) {
    	params.timer = 50000;
    }
    params.startDate = +new Date();
    localStorage.setItem(params.key, JsonBig.stringify(params));
  },
  getItem<T> (key: string): (StorageParams<T> | false) {
    const storage: StorageParams<T> = JsonBig.parse(localStorage.getItem(key));
    const now = +new Date();
    if (!storage) {
      return false;
    }
    if (now - (storage.startDate || 0) >= (storage.timer || 0)) {
      localStorage.removeItem(key);
      return false;
    }
    return storage;
  },
  removeItem (key: string) {
    if (key) {
      localStorage.removeItem(key);
    }
  }
};
