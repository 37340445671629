import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileOpNetworkWebEIP',
  name: 'mobile-eip',
  redirect: '/MobileOpNetworkWeb/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'mobile-eip-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/eip/new/index.vue'),
      meta: purchaseMeta,
    },
  ]
};
