import { APIRequest } from '~/api/config';
import { MobileProductInfo, MobileCloudConsole } from '~/models/product/mobile-cloud';
import { MobileCalcPriceFormItem, MobileCalcPriceRes } from '~/models/product/mobile-cloud/price';
import { MobileAuthInfo } from '~/models/product/mobile-cloud/index';
import { MobileProductInfoForm } from '~/models/product/mobile-cloud/records';
import { MobileRecommdPoolData } from '~/models/product/mobile-cloud/region';

const API_MOBILE_INFO = {
  // 查询全部资源池信息
  fetchAllPoolInfo: (productType: string) => {
    return APIRequest.post<string>({
      url: 'mobcheck/mobilepoolinfo/poolInfo',
      data: { productType }
    });
  },
  // 查询推荐资源池
  fetchRecommendPool: (productType: string) => {
    return APIRequest.post<MobileRecommdPoolData>({
      url: 'mobcheck/mobilepoolinfo/defaultPoolInfo',
      data: { productType, ignoreInvalid: false },
      timeout: 1000 * 60
    });
  },
  // 查询产品详情
  fetchProductInfo: (data: MobileProductInfoForm) => {
    return APIRequest.post<MobileProductInfo>({
      url: 'mobcheck/mobproduct/query',
      data,
      timeout: 1000 * 60
    });
  },
  // 查询产品价格
  fetchProductPrice: (mobileProducts: MobileCalcPriceFormItem[]) => {
    return APIRequest.post<MobileCalcPriceRes[]>({
      url: 'mobcheck/mobileFlowService/calculatePriceWeb',
      data: { mobileProducts },
      notReport: true
    });
  },
  // 获取移动云token
  getMobileToken () {
    return APIRequest.get<MobileCloudConsole>({
      url: '/rncinfo/consoletoken/getToken'
    });
  },
  // 获取当前用户的认证信息
  getMobileAuthInfo () {
    return APIRequest.get<MobileAuthInfo>({ url: '/rncinfo/userrnstatus/getStatus' });
  },
  // 移动云认证
  applyMobileStatus () {
    return APIRequest.update<boolean>({ url: '/rncinfo/realnameapply/applyMobileStatus' });
  },
  
};
export { API_MOBILE_INFO };