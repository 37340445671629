import { defineStore } from 'pinia';

export const httpStore = defineStore('http', {
  state: () => {
    return {
      // 请求终止标记列表
      abortRequestList: new Map<string, AbortController>(),
      // 是否为登录中状态
      logging: false
    };
  },
  actions: {
    // 关闭当前被标记的所有请求
    cancelRequest () {
      for (const [, abort] of this.abortRequestList) {
        abort.abort();
      }
    },
    // 修改logging状态
    changeLoggingValue (bool: boolean) {
      this.logging = bool;
    }
  }
});
