import { RouteRecordRaw } from 'vue-router';
import { notLoggedPurchaseMeta, purchaseMeta } from '~/router/router-meta';


// 精臣云
export const purchase: RouteRecordRaw[] = [
  // 精臣云购买页面
  {
    path: 'NimBot',
    name: 'nim-bot',
    component: () => import('~/views/goods/ecology-cloud/nim-bot/Purchase.vue'),
    meta: notLoggedPurchaseMeta
  },
  // wps365购买页面
  {
    path: 'WpsCloud',
    name: 'wps-cloud',
    component: () => import('~/views/goods/ecology-cloud/wps-cloud/Purchase.vue'),
    meta: notLoggedPurchaseMeta
  },
  // 盖亚购买页面
  {
    path: 'GaiaCloud',
    name: 'gaia-cloud',
    component: () => import('~/views/goods/ecology-cloud/gaia-cloud/Purchase.vue'),
    meta: notLoggedPurchaseMeta
  },
  // 一站式上云
  {
    path: '/EasilyCloud',
    name: 'easily-cloud',
    component: () => import('~/views/goods/ecology-cloud/easily-cloud/Purchase.vue'),
    meta: purchaseMeta
  }
];

