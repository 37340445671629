import { RouteRecordRaw } from 'vue-router';
import { consoleBaseMeta, individualBaseMeta, purchaseMeta } from '~/router/router-meta';


export const purchase: RouteRecordRaw[] = [
  // 阿里云购买页面
  {
    path: '/AliPurchase',
    name: 'ali-purchase',
    component: () => import('~/views/goods/ali-cloud/Purchase.vue'),
    meta: purchaseMeta
  },
  // 阿里云结算订单页面
  {
    path: '/AliSettlement/:orderId',
    name: 'ali-pay-order',
    component: () => import('~/views/goods/ali-cloud/Settlement.vue'),
    meta: purchaseMeta
  }
];
const menuProps = {
  name: '阿里云',
  show: false
};
// 阿里云订单
export const order: RouteRecordRaw = {
  path: 'AliCloud',
  name: 'ali-order',
  component: () => import('~/views/goods/ali-cloud/Order.vue'),
  meta: individualBaseMeta,
  menuProps
};

// 阿里云续费
export const renewal: RouteRecordRaw = {
  path: 'AliCloud',
  name: 'ali-renewal',
  component: () => import('~/views/goods/ali-cloud/Renewal.vue'),
  meta: individualBaseMeta,
  menuProps
};
// 阿里云控制台
export const consolePage: RouteRecordRaw = {
  path: 'AliCloud',
  name: 'ali-console',
  component: () => import('~/views/goods/ali-cloud/Console.vue'),
  meta: consoleBaseMeta,
};