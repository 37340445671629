import Http, { type HttpInterceptor, type HttpResponseType } from './http';
import { RequestConfig } from './config';
import { ElMessage } from 'element-plus';
import { getUTCDate } from '~/utils/util';
import md5 from 'js-md5';

const { VITE_BASE_URL } = import.meta.env;
const resultMethod = (res: HttpResponseType) => {
  const { url } = res.config;
  // 特定阿里云接口
  if (url?.includes('useraliyun/aliRegister')) {
    return res?.data.msg;
  }
  // 特定的阿里云认证接口
  if (url?.includes('blade-user/useraliyun/newQueryBidUserCertifiedInfo')) {
    return res?.data;
  }
  // 特定的阿里云注册接口
  if (url?.includes('blade-user/useraliyun/createAccount')) {
    return res?.data;
  }
  // 特定阿里云获取认证链接接口
  if (url?.includes('blade-user/useraliyun/generateAliyunBidCertWaysUrl')) {
    if (res?.data?.Data) {
      return res?.data?.Data;
    }
    return res?.data;
  }
  if (url?.includes('blade-user/useraliyun/editAccountStatus')) {
    return res?.data;
  }
  // 特定只返回true的接口
  if (res?.data?.toString() === 'true') {
    return true;
  }
  // 特定只返回了size的接口
  if (res?.data?.size) {
    return res.data;
  }
  return false;
};

// 公共拦截器
const publicInterceptor: HttpInterceptor = {
  // 响应拦截
  responseInterceptor: res => {
    const { data, config, headers } = res;
    const otherResult = resultMethod(res);
    if (otherResult) {
      return config.needHeaders ? { data: otherResult, headers } : otherResult;
    }
    // 若data中包含access_token字段
    if (data?.access_token) {
      return config.needHeaders ? { data, headers } : data;
    }
    // 判断code 提示报错
    if (!config.notReport && +(data?.code) !== 200 && (data?.code) !== 0) {
      ElMessage.error(data.msg);
    }
    // 判断是否需要全部的返回数据
    if (config.needAllResponse) {
      return res;
    }
    // 若需要深层data数据
    if (config.needDeepData) {
      // 判断是否需要header
      return config.needHeaders ? { data: data.data, headers } : data.data;
    }
    // 判断是否需要header
    return config.needHeaders ? { data, headers } : data;
  }
};

export const APIRequest = new Http(Object.assign(RequestConfig, {
  baseURL: VITE_BASE_URL,
  retry: 4,
  retryDelay: 10000,
  interceptors: publicInterceptor
}));


// 百度拦截器
const baiduInterceptor: HttpInterceptor = {
  requestInterceptor: config => {
    const { url, method } = config;
    if (url && method) {
      const timestamp = getUTCDate();
      const fingerprint = `${method.toUpperCase()}/${url}${timestamp}ieq%$jsaf23!@fkjwie`;
      Reflect.set(config.headers, 'timestamp', `${timestamp}@${md5(fingerprint)}`);
    }
    return config;
  },
  responseInterceptor: res => {
    const {
      data: { code, msg },
      config: { notReport }
    } = res;
    if (code && code === -1) {
      if (notReport) {
        return Promise.reject({ error: true, msg });
      }
      ElMessage.warning(msg);
      return false;
    }
    return res?.data;
  },
};
export const APIBaiduDomain = new Http(Object.assign(RequestConfig, {
  baseURL: '/baiduDomainName/',
  retry: 4,
  retryDelay: 10000,
  interceptors: baiduInterceptor
}));
