
export const RequestConfig = {
  // 接口调用计数
  retryCount: 0,
  // 超时时间
  timeout: 10 * 1000,
  // 请求体最大体积
  maxBodyLength: 5 * 1024 * 1024,
  // 设置同源 / 跨域
  withCredentials: true,
  // 接口是否可终止 默认所有接口可以被终止
  canBeCancel: true,
  // 接口是否需要登陆 默认所有接口需要登陆
  isNeedLogin: true,
  // 是否需要返回深层data
  needDeepData: true,
  // 是否需要返回headers
  needHeaders: false,
  // 是否禁用弹框警告
  notReport: false,
  // 是否需要全部数据
  needAllResponse: false,
  // 请求头
  headers: {
    'Cache-Control': 'no-store'
  }
};
